<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb">
    </div> -->
    <!--主体内容-->
    <div class="content">
      <div class="searchWrapper">
        <el-form label-position="right" label-width="150px" :model="formInline" :ref="'form'">
          <div style="border: 1px solid #c0ccda; border-radius: 4px; padding: 15px">
            <h2 class="title">
              <div class="title_icon"></div>
              <span v-if="!isEdit">添加条件派发</span>
              <span v-if="isEdit">修改条件派发</span>
            </h2>
            <div style="width: 800px">
              <el-form-item label="活动名称:" required>
                <el-input
                  maxlength="50"
                  minlength="2"
                  v-model.trim="formInline.couponActivityName"
                  placeholder="输入活动名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="活动时间:" required>
                <el-date-picker
                  v-model.trim="formInline.start"
                  type="datetimerange"
                  placeholder="选择日期"
                  :picker-options="pickerOptions1"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="关联优惠券:" required class="coupon_box">
                <ticket-item ref="ticketFrom" v-model="formInline" />
              </el-form-item>
              <!-- <el-form-item label="总发行量:" prop="publishAmount" style="width: 450px;">
                      <el-input maxlength="9" minlength="1" v-model.trim="formInline.publishAmount" :min="0" style="width: 160px;" placeholder=""></el-input><span>×关联优惠券数</span>
                    </el-form-item> -->
              <!-- <el-form-item label="推送通知:" prop="couponPushMode" style='width:400px'>
                      <template>
                        <el-checkbox-group v-model="formInline.couponPushMode">
                          <el-checkbox label="0" value="0">APP推送</el-checkbox>
                          <el-checkbox label="1" value="1">短信推送</el-checkbox>
                          <el-checkbox label="2" value="2">公众号</el-checkbox>
                        </el-checkbox-group>
                      </template>
                    </el-form-item> -->
              <!-- <el-form-item label="推送频次:" prop="pushRate" style="width: 600px;">
                      <template>
                        <el-checkbox-group v-model="formInline.pushRate">
                          <el-checkbox label="0">到账推送</el-checkbox><br>
                          <el-checkbox style="margin-right: 20px" label="1">过期推送</el-checkbox><span>距离过期</span><el-input :disabled="formInline.pushRate.indexOf('1') == -1 ? true : false" style="width: 100px;" v-model.trim="formInline.expiredDays" placeholder=""></el-input><span>天提醒</span>
                        </el-checkbox-group>

                        </template>
                    </el-form-item> -->
              <el-form-item label="触发条件:" required>
                <el-select
                  v-model="formInline.fireConditions"
                  multiple
                  placeholder="请选择"
                  style="width: 400px"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.code"
                    :label="item.desc"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="推送通知:" required>
                <el-checkbox-group v-model="formInline.couponPushMode">
                  <el-checkbox label="0">APP推送</el-checkbox>
                  <el-checkbox label="1">短信推送</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <!-- <el-form-item label="活动推送内容:" prop="pushContent" style="width: 1000px;">
                      <el-input type="textarea" @input="descInput"  maxlength="200" :autosize="{ minRows: 5, maxRows: 4}" placeholder="请输入内容" v-model="formInline.pushContent">
                      </el-input>
                      <span style="position: absolute; bottom: 5px; right: 10px; color: #b3d9d9; font-size: 11px;">{{remnant}}/200</span>
                    </el-form-item> -->
            </div>
          </div>
        </el-form>
        <div style="text-align: center; margin: 20px 0">
          <el-button type="primary" @click="submitData" style="width: 88px">提交</el-button>
          <el-button type="" @click="$router.go(-1)" style="width: 88px">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ticketItem from "@/components/ticketAddItem";
export default {
  name: "conditionalDistributionadd",
  data() {
    return {
      tenantLists: [],
      remnant: 0,
      value5: [],
      value11: [],
      num1: 0,
      radio: "1",
      isEdit: this.$route.query.couponActivityId,
      couponActivityId: this.$route.query.couponActivityId,
      oldParkname: "",
      hasSelect: "",
      rsTypeList: [],
      parkTypeArr: [
        {
          name: "地上",
          value: "1",
        },
        {
          name: "地下",
          value: "0",
        },
        {
          name: "地上+地下",
          value: "2",
        },
      ],
      upData: {
        "Session-Id": sessionStorage.token,
      },
      activeName: "1",
      value1: "",
      url: "",
      file: "",
      picFlag: "false",
      payWayAll: [],
      parkTypeAll: [],
      workStateAll: [],
      tenantList: [],
      headers: {
        "Session-Id": sessionStorage.token,
      },
      map: "",
      pic: true,
      temLng: "",
      temLat: "",
      temAddress: "",
      areaList: "",
      city: "",
      geocoder: "",
      dialogMapVisible: false,
      zoom: 16,
      center: [114.520932, 38.050882],
      markers: [
        {
          position: [114.520932, 38.050882],
          events: {
            click: (eventName, handler, context) => {
              // 逆地理编码
              var lnglatXY = [eventName.lnglat.lng, eventName.lnglat.lat]; // 地图上所标点的坐标
              this.temLng = eventName.lnglat.lng;
              this.temLat = eventName.lnglat.lat;
              this.formInline.hasSelect = lnglatXY.join(",");
              this.geocoder.getAddress(lnglatXY, (status, result) => {
                if (status === "complete" && result.info === "OK") {
                  // 获得了有效的地址信息:
                  // 即，result.regeocode.formattedAddress
                  this.formInline.address = result.regeocode.formattedAddress;
                } else {
                  // 获取地址失败
                  this.$alert("fail");
                }
              });
            },
            dragend: (e) => {
              this.markers[0].position = [e.lnglat.lng, e.lnglat.lat];
            },
          },
          visible: true,
          draggable: false,
        },
      ],
      windows: [],
      imageData: {
        param: JSON.stringify({ token: sessionStorage.getItem("token") }),
      },
      imageUrl: "",
      loading: false,

      detailFlag: false,
      pickerOptions1: {
        disabledDate(time) {
          if (time.getFullYear() < new Date().getFullYear()) {
            return true;
          } else if (
            time.getFullYear() <= new Date().getFullYear() &&
            time.getMonth() < new Date().getMonth()
          ) {
            return true;
          } else if (
            time.getFullYear() <= new Date().getFullYear() &&
            time.getMonth() <= new Date().getMonth() &&
            time.getDate() < new Date().getDate()
          ) {
            return true;
          } else {
            return false;
          }
        },
      },
      formInline: {
        couponActivityId: "",
        couponActivityName: "",
        start: "",
        startTime: "",
        endTime: "",
        couponRuleActivities: [
          {
            couponRuleId: "",
            onceReceive: 1,
            publishAmount: "",
          },
        ],
        couponPushMode: [],
        fireConditions: [],
      },
      options: [],
    };
  },
  methods: {
    handleGetDroplist() {
      let opt = {
        method: "get",
        url: "/acb/2.0/couponActivity/droplist",
        success: (res) => {
          this.options = res.value;
        },
      };
      this.$request(opt);
    },
    addTicket() {
      this.formInline.couponRuleActivities.push({
        couponRuleId: "",
        onceReceive: 1,
        publishAmount: "",
      });
    },
    check(rule, value, callback) {
      if (this.formInline.publishAmount == "") {
        this.$message("请输入总发行量!");
      }
    },
    checkOne(rule, value, callback) {
      if (value <= 2) {
        callback(new Error("请最少输入两个字符"));
      } else {
        let opt = {
          method: "post",
          url: "/gplus/couponActivity/checkNameExist",
          data: {
            couponActivityId: this.formInline.couponActivityId,
            couponActivityName: this.formInline.couponActivityName,
            type: "1",
          },
          success: (res) => {
            if (res.data.result == false) {
              callback(new Error("活动名重复"));
            } else {
              callback();
            }
          },
        };
        // getData(opt)
      }
    },
    checkpublishAmount(rule, value, callback) {
      if (!/(^[1-9]{1}[0-9]*$)/.test(value) && value != "") {
        callback(new Error("请输入大于0的整数"));
      } else {
        callback();
      }
    },
    checkpushRate(rule, value, callback) {
      if (value == 1 || value.length > 1) {
        if (
          !/(^[1-9]{1}[0-9]*$)/.test(this.formInline.expiredDays) ||
          this.formInline.expiredDays == ""
        ) {
          callback(new Error("请输入大于0的整数"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    checkoneDay(rule, value, callback) {
      if (value == 2) {
        if (
          !/(^[1-9]{1}[0-9]*$)/.test(this.formInline.oneDayTimes) ||
          this.formInline.oneDayTimes == ""
        ) {
          callback(new Error("请输入大于0的整数"));
        } else if (this.formInline.oneDayTimes * 1 > this.formInline.publishAmount * 1) {
          callback(new Error("请输入小于总发行量的整数"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    descInput() {
      var txtVal = this.formInline.pushContent.length;
      this.remnant = txtVal;
    },
    getRsType() {
      let opt = {
        url: "/gplus/park/queryAllRsParkType",
        data: {},
        success: (res) => {
          this.rsTypeList = JSON.parse(res.data.RsParkType);
        },
      };
      // getData(opt);
    },
    errHandle(err, flie) {
      if (err.toString().indexOf("911") != -1) {
        this.$alert("登录失效，请重新登录", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        }).then(() => {
          // eslint-disable-next-line no-restricted-globals
          location.replace("/");
        });
      } else if (err.toString().indexOf("timeout") != -1) {
        this.$alert("当前网络不稳定，请稍候重试", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        }).then(() => {});
      }
    },
    // 获取商户
    getTenant() {
      let opt = {
        url: "/gplus/bdTenant/getAllTenants",
        data: {},
        success: (res) => {
          this.tenantList = res.data.allTenants;
        },
      };
      // getData(opt);
    },
    // 获取优惠券
    couponRuleIds() {
      const tempData = {
        page: 1,
        pageSize: 100,
      };
      const opt = {
        method: "get",
        url: "/acb/2.0/coupon/list",
        data: tempData,
        success: (res) => {
          this.tenantLists = res.value.list;
        },
      };
      this.$request(opt);
    },
    mapCancel() {
      this.dialogMapVisible = false;
      this.formInline.hasSelect = "";
      this.formInline.address = "";
    },
    mapSure() {
      this.formInline.lat = this.temLat;
      this.formInline.lng = this.temLng;
      //      this.formInline.hasSelect = this.temLat + ',' + this.temLng;
      this.dialogMapVisible = false;
    },
    searchMap() {
      var placeSearch = new AMap.PlaceSearch({
        // 构造地点查询类
        pageSize: 5,
        pageIndex: 1,
        city: "石家庄", // 城市
      });
      placeSearch.search(this.city, (status, result) => {
        this.onSearchResult(result.poiList.pois);
      });
    },
    getArea() {
      let opt = {
        url: "/gplus/city/queryAreaListByUserAndCity",
        data: {},
        success: (res) => {
          this.areaList = res.data.CityVO;
        },
      };
      // getData(opt);
    },
    handleAvatarSuccess(res, file) {
      setTimeout(() => {
        this.formInline.image = res.data.image;
      }, 1000);
    },
    getParkDetail() {
      let opt = {
        url: "/gplus/park/queryById",
        data: {
          parkId: this.$route.query.parkId,
        },
        success: (res) => {
          for (let i in this.formInline) {
            this.formInline[i] = res.data.ParkVO[i] == null ? "" : res.data.ParkVO[i] + "";
          }
          if (this.formInline.accessType != 0) {
            this.oldParkname = this.formInline.parkName;
          }
          this.formInline.hasSelect = this.formInline.lng + "," + this.formInline.lat;
          this.formInline.parkId = res.data.ParkVO.parkId;
          this.formInline.closeParkId = res.data.ParkVO.closeParkId;
        },
        fail: (res) => {
          this.$alert(res.msg, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        },
      };
      // getData(opt);
    },
    beforeAvatarUpload(file) {
      let that = this;
      const isJPG = file.type === "image/jpeg" || "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 或 png格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      if (isJPG && isLt2M) {
        that.pic = false;
      }
      return isJPG && isLt2M;
    },
    showLog(data) {
      // if (data) {
      //   let expiryDate = dateFormat(data, 'yyyy-MM-dd');
      //   this.formInline.expiryDate = expiryDate;
      // } else {
      //   this.formInline.expiryDate = '';
      // }
    },
    // 提交
    submitData() {
      if (!this.checkForm()) {
        return;
      }
      const tempData = {};
      tempData.couponActivityName = this.formInline.couponActivityName;
      tempData.startTime = this.formInline.start[0];
      tempData.endTime = this.formInline.start[1];
      const couponRuleActivities = [];
      this.formInline.couponRuleActivities.forEach((item) => {
        const _item = {};
        _item.couponRuleId = item.couponRuleId;
        _item.onceReceive = item.onceReceive;
        _item.publishAmount = item.publishAmount;
        // if (_item.onceReceive < 1 || _item.publishAmount < 1) {
        //   this.$alert('优惠券请输入大于0的整数', this.$t('pop_up.Tips'), {
        //     confirmButtonText: this.$t('pop_up.Determine')
        //   })
        //   return;
        // }
        couponRuleActivities.push(_item);
      });
      tempData.couponRuleActivities = JSON.parse(JSON.stringify(couponRuleActivities));
      let suffix =
        "?couponActivityName=" +
        tempData.couponActivityName +
        "&startTime=" +
        tempData.startTime +
        "&endTime=" +
        tempData.endTime;
      if (this.couponActivityId) {
        suffix += "&couponActivityId=" + this.couponActivityId;
      }
      suffix +=
        `&fireConditions=` +
        this.formInline.fireConditions.join(",") +
        "&couponPushMode=" +
        this.formInline.couponPushMode.join(",");
      let opt = {
        method: "post",
        url: this.isEdit
          ? "/acb/2.0/couponActivity/update" + suffix
          : "/acb/2.0/couponActivity/create" + suffix,
        data: tempData.couponRuleActivities,
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        success: (res) => {
          this.$alert("操作成功", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          this.$router.push({ path: "/couponConditionDistribute" });
        },
      };
      this.$request(opt);
    },
    // 表单验证
    checkForm() {
      if (!this.formInline.couponActivityName) {
        this.$alert("请填写活动名称", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      } else if (this.formInline.couponActivityName.length < 2) {
        this.$alert("活动名称不得小于2个字符", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      } else if (!this.formInline.start) {
        this.$alert("请选择活动时间", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      } else if (this.formInline.fireConditions.length == 0) {
        this.$alert("请选择触发条件", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      } else if (this.formInline.couponPushMode.length == 0) {
        this.$alert("请选择推送通知", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      } else {
        const umptyItem = this.formInline.couponRuleActivities.filter((item) => {
          const flag1 = !item.couponRuleId || !item.onceReceive || !item.publishAmount;
          const flag2 = item.publishAmount % item.onceReceive !== 0;
          const flag3 = !/^[1-9]$/.test(item.onceReceive);
          const flag4 = item.publishAmount == 0;
          return flag1 || flag2 || flag3 || flag4;
        });
        if (umptyItem.length > 0) {
          this.$alert(
            "优惠券信息填写有误(优惠券总张数必须是每次领取张数的倍数，且每次领取张数范围为1-9)",
            this.$t('pop_up.Tips'),
            {
              confirmButtonText: this.$t('pop_up.Determine'),
            }
          );
          return false;
        }
        return true;
      }
    },
    onSearchResult(pois) {
      let latSum = 0;
      let lngSum = 0;
      this.markers = [];
      this.map.setZoom(13);
      if (pois.length > 0) {
        pois.forEach((poi) => {
          let { lng, lat } = poi.location;
          lngSum += lng;
          latSum += lat;
          this.markers.push({
            position: [lng, lat],
            events: {
              click: (eventName, handler, context) => {
                // 逆地理编码
                var lnglatXY = [eventName.lnglat.lng, eventName.lnglat.lat]; // 地图上所标点的坐标
                this.temLng = eventName.lnglat.lng;
                this.temLat = eventName.lnglat.lat;
                this.formInline.hasSelect = lnglatXY.join(",");
                this.geocoder.getAddress(lnglatXY, (status, result) => {
                  if (status === "complete" && result.info === "OK") {
                    // 获得了有效的地址信息:
                    // 即，result.regeocode.formattedAddress
                    this.formInline.address = result.regeocode.formattedAddress;
                    this.temAddress = result.regeocode.formattedAddress;
                  } else {
                    // 获取地址失败
                    this.$alert("fail");
                  }
                });
              },
              dragend: (e) => {
                this.markers[0].position = [e.lnglat.lng, e.lnglat.lat];
              },
            },
          });
        });
        let center = {
          lng: lngSum / pois.length,
          lat: latSum / pois.length,
        };
        this.center = [center.lng, center.lat];
      }
    },
    // 获取详情信息
    getDetailInfo() {
      const opt = {
        method: "get",
        url: "/acb/2.0/couponActivity/getById",
        data: { couponActivityId: this.couponActivityId },
        success: (res) => {
          const value = res.value;
          this.formInline.couponActivityName = value.couponActivityName;
          this.formInline.start = [value.startTime, value.endTime];
          this.formInline.couponRuleActivities = value.couponRuleActivities;
          this.formInline.fireConditions = value.fireConditions.split(",").map(Number);
          this.formInline.couponPushMode = value.couponPushMode.split(",");
        },
      };
      this.$request(opt);
    },
  },
  components: {
    ticketItem,
  },
  created() {
    // this.getTenant();
    // this.getArea();
    // this.getRsType();
    // if (this.$route.query.parkId) {
    //   this.getParkDetail();
    //   this.isEdit = true;
    // }
    this.handleGetDroplist();
  },
  mounted() {
    if (this.couponActivityId) {
      this.getDetailInfo();
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
}

#addTicket {
  cursor: pointer;
  font-size: 40px;
  color: #0f6eff;
  display: inline-block;
  vertical-align: top;
  margin-top: -3px;
}

.marginSty {
  margin-bottom: 20px;
}
::v-deep .coupon_box.el-form-item {
  height: auto;
}
</style>
<style>
.tip {
  position: absolute;
  left: 46px;
  top: 113px;
  color: gray;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: visible;
}
.avatar-uploader .el-upload:hover {
  border-color: #20a0ff;
}
.avatar-uploader .no {
  position: absolute;
  left: 100%;
  margin-left: 10px;
  bottom: 0;
  display: block;
  width: 50px;
  color: gray;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.map {
  position: absolute;
  right: -100px;
  top: 0;
}
.amap-demo {
  height: 500px;
  width: 100%;
}

.prompt {
  background: white;
  width: 100px;
  height: 30px;
  text-align: center;
}
.search {
  float: left;
}
.lnglat {
  float: left;
  margin-left: 10px;
}
.map_bottom {
  padding-top: 20px;
}
.map_button {
  float: right;
}
</style>
