var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "searchWrapper" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-position": "right",
                "label-width": "150px",
                model: _vm.formInline,
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #c0ccda",
                    "border-radius": "4px",
                    padding: "15px",
                  },
                },
                [
                  _c("h2", { staticClass: "title" }, [
                    _c("div", { staticClass: "title_icon" }),
                    !_vm.isEdit
                      ? _c("span", [_vm._v("添加条件派发")])
                      : _vm._e(),
                    _vm.isEdit
                      ? _c("span", [_vm._v("修改条件派发")])
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { width: "800px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动名称:", required: "" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "50",
                              minlength: "2",
                              placeholder: "输入活动名称",
                            },
                            model: {
                              value: _vm.formInline.couponActivityName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline,
                                  "couponActivityName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInline.couponActivityName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动时间:", required: "" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              placeholder: "选择日期",
                              "picker-options": _vm.pickerOptions1,
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "default-time": ["00:00:00", "23:59:59"],
                            },
                            model: {
                              value: _vm.formInline.start,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline,
                                  "start",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInline.start",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "coupon_box",
                          attrs: { label: "关联优惠券:", required: "" },
                        },
                        [
                          _c("ticket-item", {
                            ref: "ticketFrom",
                            model: {
                              value: _vm.formInline,
                              callback: function ($$v) {
                                _vm.formInline = $$v
                              },
                              expression: "formInline",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "触发条件:", required: "" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: { multiple: "", placeholder: "请选择" },
                              model: {
                                value: _vm.formInline.fireConditions,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formInline,
                                    "fireConditions",
                                    $$v
                                  )
                                },
                                expression: "formInline.fireConditions",
                              },
                            },
                            _vm._l(_vm.options, function (item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: { label: item.desc, value: item.code },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "推送通知:", required: "" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.formInline.couponPushMode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formInline,
                                    "couponPushMode",
                                    $$v
                                  )
                                },
                                expression: "formInline.couponPushMode",
                              },
                            },
                            [
                              _c("el-checkbox", { attrs: { label: "0" } }, [
                                _vm._v("APP推送"),
                              ]),
                              _c("el-checkbox", { attrs: { label: "1" } }, [
                                _vm._v("短信推送"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", margin: "20px 0" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "88px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.submitData },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "88px" },
                  attrs: { type: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }